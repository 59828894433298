import React, { useEffect, useState } from 'react';
import {
  DashboardTopWebinarsQuery,
  useDashboardTopWebinarsLazyQuery,
} from '../../../graphql/__types';
import { Loader } from '../../Shared/Loaders/Loader';
import { CardWrapper } from '../../Shared/Wrapper/CardWrapper';

export function DashboardTopWebinarList() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState<DashboardTopWebinarsQuery>({
    getTopWebinars: [],
  });
  const [pagedData, setPagedData] = useState([]);

  const [getTopWebinars] = useDashboardTopWebinarsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setData(data);
      setIsLoaded(true);
    },
    onError: (error) => {
      console.error(error);
      setIsLoaded(true);
    },
  });

  useEffect(() => {
    getTopWebinars();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (!!data && !!data.getTopWebinars) {
        const paged = data.getTopWebinars.slice(0, 10) as any;
        if (paged) setPagedData(paged);
      }
    }
  }, [isLoaded]);

  function formatNumber(value: any) {
    const newValue = Number(value);
    return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function handleLoadMore() {
    setPagedData(data.getTopWebinars as any);
  }

  return (
    <section className="mt-5">
      <CardWrapper>
        <h1 className="text-sm font-medium text-gray-600 mb-5">
          Webinar Registrations
        </h1>

        {isLoaded ? (
          <>
            {pagedData.length > 0 ? (
              pagedData.map((item: any) => {
                if (!item) return;

                return (
                  <section
                    key={item.id}
                    className="cursor-pointer px-2 hover:bg-gray-100 flex flex-row space-x-2 space-y-2 justify-between"
                  >
                    <section className="text-sm overflow-ellipsis">
                      {item.name}
                    </section>
                    <section className="text-sm font-semibold">
                      {formatNumber(item.registrations)}
                    </section>
                  </section>
                );
              })
            ) : (
              <h1 className="text-center uppercase font-medium">
                No Records Yet
              </h1>
            )}

            {pagedData.length <
            (!!data && !!data.getTopWebinars && data.getTopWebinars.length) ? (
              <section
                className="mt-5 text-sm text-center font-semibold uppercase cursor-pointer hover:text-primary-1"
                onClick={() => handleLoadMore()}
              >
                Load All
              </section>
            ) : null}
          </>
        ) : (
          <section className="flex justify-center">
            <Loader />
          </section>
        )}
      </CardWrapper>
    </section>
  );
}
