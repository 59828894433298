import {
  UsersIcon,
  VideoCameraIcon,
  TableIcon,
  TicketIcon,
  HeartIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import {
  DashboardMetricsQuery,
  useDashboardMetricsLazyQuery,
} from '../../../graphql/__types';
import { DashboardMetricItem } from './DashboardMetricItem';

export function DashboardMetrics() {
  const [isMetricsLoaded, setIsMetricsLoaded] = useState(false);
  const [data, setMetricsData] = useState<DashboardMetricsQuery>({
    getMetrics: {
      numberOfActivities: 0,
      numberOfCertificatesGenerated: 0,
      numberOfRegistrations: 0,
      numberOfUsers: 0,
      numberOfWebinars: 0,
    },
  });

  const [getMetrics] = useDashboardMetricsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsMetricsLoaded(true);
      setMetricsData(data);
    },
    onError: (error) => {
      console.error(error);
      setIsMetricsLoaded(true);
    },
  });

  useEffect(() => {
    getMetrics();
  }, []);

  return (
    <section className="mt-5 grid gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      <DashboardMetricItem
        isLoaded={isMetricsLoaded}
        title="Total Users"
        value={data.getMetrics.numberOfUsers}
        icon={<UsersIcon className="w-5 h-5 text-primary-1" />}
      />

      <DashboardMetricItem
        isLoaded={isMetricsLoaded}
        title="Total Webinars"
        value={data.getMetrics.numberOfWebinars}
        icon={<VideoCameraIcon className="w-5 h-5 text-primary-2" />}
      />

      <DashboardMetricItem
        isLoaded={isMetricsLoaded}
        title="Total Activities"
        value={data.getMetrics.numberOfActivities}
        icon={<TicketIcon className="w-5 h-5 text-secondary-1" />}
      />

      <DashboardMetricItem
        isLoaded={isMetricsLoaded}
        title="Registrations"
        value={data.getMetrics.numberOfRegistrations}
        icon={<TableIcon className="w-5 h-5 text-secondary-2" />}
      />

      <DashboardMetricItem
        isLoaded={isMetricsLoaded}
        title="Certificates Generated"
        value={data.getMetrics.numberOfCertificatesGenerated}
        icon={<HeartIcon className="w-5 h-5 text-secondary-3" />}
      />
    </section>
  );
}
