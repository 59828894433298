import React from 'react';
import { Router } from '@reach/router';
import { Dashboard } from '../components/Dashboard';
import { PrivateRoute } from '../components/Shared/PrivateRoute';
import { PublicLayout } from '../components/Shared/PublicLayout';
import SeoHeader from '../components/Shared/SeoHeader';
import { NotFoundRedirect } from '../components/Shared/Redirects/NotFoundRedirect';

export default function DashboardPage() {
  return (
    <PublicLayout>
      <SeoHeader title="Dashboard" />
      <Router basepath="/dashboard">
        <PrivateRoute
          component={Dashboard}
          path="/"
          roles={['Administrator', 'Organizer']}
        />
        <NotFoundRedirect path="*" />
      </Router>
    </PublicLayout>
  );
}
