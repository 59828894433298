import React from 'react';
import { PageTitle } from '../Shared/PageTitle';
import { Wrapper } from '../Shared/Wrapper';
import { DashboardMetrics } from './DashboardMetric';
import { DashboardTopWebinarList } from './DashboardTopWebinarList';

export function Dashboard() {
  return (
    <Wrapper>
      <section className="flex flex-col w-full py-5">
        <PageTitle value="Dashboard" />
        <DashboardMetrics />

        <section className="mt-5 grid gap-5 sm:grid-cols-2 md:grid-cols-3">
          <DashboardTopWebinarList />
        </section>
      </section>
    </Wrapper>
  );
}
