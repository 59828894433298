import React, { useEffect, useState } from 'react';
import { Loader } from '../../Shared/Loaders/Loader';

type Props = {
  isLoaded: boolean;
  title: string;
  icon: JSX.Element;
  value?: string | number | null | undefined;
};

export function DashboardMetricItem({ isLoaded, title, value, icon }: Props) {
  const [formattedValue, setFormattedValue] = useState<string>('0');

  useEffect(() => {
    if (isLoaded && value) {
      const newValue = Number(value);
      setFormattedValue(
        newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      );
      return;
    }

    setFormattedValue('N/A');
  }, [isLoaded, value]);

  return (
    <section className="border border-gray-100 p-3 rounded-lg shadow-xs overflow-hidden">
      <section className="flex flex-row items-center justify-left">
        <div className="p-3 rounded-full bg-gray-50 mr-4">{icon}</div>

        <section>
          <h1 className="mb-2 text-sm font-medium text-gray-600">{title}</h1>
          <section className="text-lg font-semibold text-gray-700">
            {isLoaded ? (
              formattedValue
            ) : (
              <section
                className={`w-3 h-3 mt-2 border-b-2 border-primary-1 rounded-full animate-spin`}
              ></section>
            )}
          </section>
        </section>
      </section>
    </section>
  );
}
